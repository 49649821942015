
.main-wrapper {
  height: inherit;
  padding-top: 60px; 
}

.main-wrapper-content {
  height: inherit; 
  font-family: "Roboto";
}

.main-content {
  top: 0px;
  left: 0px;
  margin-left: 223px; /* -8px from browser */
  margin-top: 53px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  padding-left: 20px;
  padding-top: 20px;
}

.top-header {
  position: fixed;
  left: 230px;
  height: 52px;
  right: 0px;
  top: 0px;
  border-bottom: 1px solid #CFD8DC;
  padding-left: 26px;
  color: rgba(0, 0, 0, 0.37);
  font-weight: 500;
  font-size: 16px;
  display: flex;
  flex: 1;
  align-items: center;
}

.side-bar-container {
  top: 0px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 230px;
  background: #121f31;
  border-right: 1px solid #CFD8DC;
}

.side-bar-logo-container {
  height: 52px;
  display: flex;
  flex: 1;
  flex-grow: 1;
  align-items: center;
  padding-left: 46px;
  text-align: center;
  transition-duration: 0.2s;
  cursor: pointer;
}

.side-bar-logo-container:hover {
  background: #1f2c3d;
}

.side-bar-logo {
  height: 22px;
  object-fit: contain;
}

.logo-text {
  font-size: 28px;
  font-weight: 500;
  color: white; 
}

.side-bar-menu {
  margin-top: 10px;
}

.side-bar-menu-item {
  display: flex;
  flex: 1;
  height: 50px;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  transition-duration: 0.4s;
  padding-left: 46px;
  margin-bottom: 10px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.4);
}

.side-bar-menu-item:hover {
  background: #1f2c3d;
}

.selected {
  background: #1f2c3d;
  color: white;
  font-weight: 500;
}
