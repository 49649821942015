.label-input-content {
  min-height: 58px;
  display: flex;
  flex: 1;
  max-width: 385px;
  justify-content: space-evenly;
}

.pick-order-container {
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-direction: column;
}

.preview-label-container {
  margin-top: 20px;
  border: 1px dashed gray;
  flex-direction: column;
  width: 385px;
}

.label-buttons-container {
  max-width: 385px;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
  align-items: center;
}

.divider {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  border: 0.5px solid black;
}