body {
  margin: 0px !important;
  padding: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading-container {
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  height: 100%;
  font-size: 50px;
}
.loading-container div {
  margin-top: 10px;
}
