.label-area {
  width: 384px;
  height: 384px;
  margin: 0;
  overflow: hidden; 
}

.label-header {
  display: flex;
  flex-direction: row;
  flex: 1;
  justify-content: space-evenly;
  height: 40px; 
  font-size: 35px;
}

.label-shipment-logo {
  height: 35px;
}

/* TO DO: clarify if text is needed inside this className */
.label-ship-detail {
  font-weight: 700;
  padding: 0;
  line-height: 10px;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  align-items: center; 
}

.label-top-barcode-container {
  display: flex;
  width: 100%;
  border-bottom: 2px solid black;
}

.label-top-barcode {
  display: flex;
  z-index: -1;
  width: 80%;
  margin-left: 9.5%;
}

.label-bottom-barcode-container {
  display: flex;
  max-height: 80px;
  width: 100%;
  font-size: 10px !important;
  font-weight: 700;
  justify-content: center; 
}

.barcode-text {
  align-self: center;
  font-size: 10px; 
}

.label-top-content {
  display: flex;
  flex-direction: column;
  height: 130px;
  overflow: hidden;
  margin-bottom: 2px solid black;
  padding-top: 4px; 
}

.label-content {
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 162px;
  width: 100%;
  border-bottom: 2px solid black; 
}

.label-content-ship-detail {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 8px; 
}

.label-content-ship-detail span {
  line-height: 12px; 
}

.label-seller-detail {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  padding-left: 8px; 
}

.label-seller-detail span {
  line-height: 12px; 
}

.label-qr-code {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  text-align: center; 
}

.shipment-code {
  font-size: 12;
  margin: 5px 0px 5px 0px;
}

.label-qr-code span {
  line-height: 10px; 
}

.label-qr-code-container {
  height: 100px;
  width: 100px;
  max-height: 100px;
  max-width: 100px; 
}

.label-qr-code-head {
  font-size: 12px;
  font-weight: 700;
  margin-top: 4px;
  margin-bottom: 10px; 
}

.label-content-detail {
  width: 200px;
  border-right: 2px solid black;
  font-size: 11px;
  text-align: left;
  justify-content: flex-start; 
}
/* 
@media print {
  .label-area {
    width: 384px;
    height: 384px;
    margin: 0px;
    padding: 0px;
    overflow: hidden; 
  }
  .page-break {
    page-break-after: always; 
  } 
  html, body { 
    width: 384px; 
    height: 384px; 
    margin: 0px;
    padding: 0px;
    font-family: "Roboto";
  } 
  @page { 
    size: 384px 384px; 
    margin: 0mm; 
    padding: 0;
  } 
} */

.label-container {
  width: 384px;
  height: 384px;
  overflow: "hidden";
  position: "fixed";
}
