@media print {
  .container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex: 1;
    page-break-after: always;
    page-break-before: always;
  }
}

.header {
  display: flex;
  flex-direction: row;
}

.header-text {
  display: flex;
  flex: 1;
  flex-grow: 1;
}

.print-area {
  padding: 1em;
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: auto;
  border: 2px dashed #bababa;
  flex-direction: column;
}

.print-content {
  display: flex;
  flex: 1;
  flex-grow: 1;
  height: inherit;
  flex-direction: column;
  width: 50%;
}

.barcode-text {
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-direction: row;
}

.flex-grow {
  flex: 1; 
  flex-grow: 1;
  display: flex;
}

.flex-grow-column {
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-direction: column;
  margin-top: 20px;
}

.flex-grow-column span {
  display: flex;
  margin-top: 4px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.flex-column span {
  display: flex;
}

.large-text-bold {
  font-size: 1.4em;
  font-weight: 700;
}

.shipment-name {
  font-size: 1.2em;
  font-weight: 700;
}

.flex-row-grow {
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-direction: row;
}

.print-area-notes {
  border: 1px solid rgba(50, 50, 50, 0.2);
  padding: 8;
  border-radius: 5;
}

.table-container {
  margin-top: 30px;
  border: 1px solid rgba(50, 50, 50, 0.2); 
  border-radius: 5px; 
  flex-direction: column;
}

.table-header {
  height: 30px;
  flex-direction: row;
  display: flex;
  border-bottom: 1px solid rgba(50, 50, 50, 0.2); 
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
}

.table-header span {
  display: flex;
  flex-grow: 1;
}

.table-content {
  display: flex;
  flex-grow: 1;
  flex: 1;
  flex-direction: column;
}
.table-content-row {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  align-items: center; 
  text-justify: center; 
  padding-left: 8px;
  border-bottom: 1px solid rgba(50, 50, 50, 0.2); 
}

.table-content-row span {
  word-break: break-word; 
  flex-wrap: wrap;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
}

.thank-you {
  font-size: 14px; 
  align-self: center;
  font-weight: 700;
  margin-top: 24;
}
