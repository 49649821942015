.complete-order-container {
  display: flex;
  flex: 1;
  flex-grow: 1;
  flex-direction: column;
}

.complete-order-content {
  min-height: 58px;
  display: flex;
  flex: 1;
  max-width: 400px;
  justify-content: space-evenly;
}