
.label-area {
  width: 384px;
  height: 384px;
  margin: 0;
  overflow: hidden; 
}

.label-header {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  justify-content: space-evenly;
  height: 40px; 
  font-size: 35px;
}

.label-shipment-logo {
  height: 35px;
}

/* TO DO: clarify if text is needed inside this className */
.label-ship-detail {
  font-weight: 700;
  padding: 0;
  line-height: 10px;
  display: flex;
  flex-direction: column;
  font-size: 11px;
  align-items: center; 
}

.label-top-barcode-container {
  display: flex;
  width: 100%;
  border-bottom: 2px solid black;
}

.label-top-barcode {
  display: flex;
  z-index: -1;
  width: 80%;
  margin-left: 9.5%;
}

.label-bottom-barcode-container {
  display: flex;
  max-height: 80px;
  width: 100%;
  font-size: 10px !important;
  font-weight: 700;
  justify-content: center; 
}

.barcode-text {
  align-self: center;
  font-size: 10px; 
}

.label-top-content {
  display: flex;
  flex-direction: column;
  height: 130px;
  overflow: hidden;
  margin-bottom: 2px solid black;
  padding-top: 4px; 
}

.label-content {
  display: flex;
  flex-direction: row;
  flex: 1 1;
  height: 162px;
  width: 100%;
  border-bottom: 2px solid black; 
}

.label-content-ship-detail {
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-left: 8px; 
}

.label-content-ship-detail span {
  line-height: 12px; 
}

.label-seller-detail {
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
  padding-left: 8px; 
}

.label-seller-detail span {
  line-height: 12px; 
}

.label-qr-code {
  display: flex;
  flex: 1 1;
  flex-direction: column;
  align-items: center;
  font-size: 10px;
  text-align: center; 
}

.shipment-code {
  font-size: 12;
  margin: 5px 0px 5px 0px;
}

.label-qr-code span {
  line-height: 10px; 
}

.label-qr-code-container {
  height: 100px;
  width: 100px;
  max-height: 100px;
  max-width: 100px; 
}

.label-qr-code-head {
  font-size: 12px;
  font-weight: 700;
  margin-top: 4px;
  margin-bottom: 10px; 
}

.label-content-detail {
  width: 200px;
  border-right: 2px solid black;
  font-size: 11px;
  text-align: left;
  justify-content: flex-start; 
}
/* 
@media print {
  .label-area {
    width: 384px;
    height: 384px;
    margin: 0px;
    padding: 0px;
    overflow: hidden; 
  }
  .page-break {
    page-break-after: always; 
  } 
  html, body { 
    width: 384px; 
    height: 384px; 
    margin: 0px;
    padding: 0px;
    font-family: "Roboto";
  } 
  @page { 
    size: 384px 384px; 
    margin: 0mm; 
    padding: 0;
  } 
} */

.label-container {
  width: 384px;
  height: 384px;
  overflow: "hidden";
  position: "fixed";
}

.label-input-content {
  min-height: 58px;
  display: flex;
  flex: 1 1;
  max-width: 385px;
  justify-content: space-evenly;
}

.pick-order-container {
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  flex-direction: column;
}

.preview-label-container {
  margin-top: 20px;
  border: 1px dashed gray;
  flex-direction: column;
  width: 385px;
}

.label-buttons-container {
  max-width: 385px;
  margin-top: 8px;
  margin-bottom: 8px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  flex-grow: 1;
  align-items: center;
}

.divider {
  margin-top: 8px;
  margin-bottom: 8px;
  width: 100%;
  border: 0.5px solid black;
}
.complete-order-container {
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  flex-direction: column;
}

.complete-order-content {
  min-height: 58px;
  display: flex;
  flex: 1 1;
  max-width: 400px;
  justify-content: space-evenly;
}

.main-wrapper {
  height: inherit;
  padding-top: 60px; 
}

.main-wrapper-content {
  height: inherit; 
  font-family: "Roboto";
}

.main-content {
  top: 0px;
  left: 0px;
  margin-left: 223px; /* -8px from browser */
  margin-top: 53px;
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  padding-left: 20px;
  padding-top: 20px;
}

.top-header {
  position: fixed;
  left: 230px;
  height: 52px;
  right: 0px;
  top: 0px;
  border-bottom: 1px solid #CFD8DC;
  padding-left: 26px;
  color: rgba(0, 0, 0, 0.37);
  font-weight: 500;
  font-size: 16px;
  display: flex;
  flex: 1 1;
  align-items: center;
}

.side-bar-container {
  top: 0px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  width: 230px;
  background: #121f31;
  border-right: 1px solid #CFD8DC;
}

.side-bar-logo-container {
  height: 52px;
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  align-items: center;
  padding-left: 46px;
  text-align: center;
  -webkit-transition-duration: 0.2s;
          transition-duration: 0.2s;
  cursor: pointer;
}

.side-bar-logo-container:hover {
  background: #1f2c3d;
}

.side-bar-logo {
  height: 22px;
  object-fit: contain;
}

.logo-text {
  font-size: 28px;
  font-weight: 500;
  color: white; 
}

.side-bar-menu {
  margin-top: 10px;
}

.side-bar-menu-item {
  display: flex;
  flex: 1 1;
  height: 50px;
  align-items: center;
  flex-direction: row;
  cursor: pointer;
  -webkit-transition-duration: 0.4s;
          transition-duration: 0.4s;
  padding-left: 46px;
  margin-bottom: 10px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.4);
}

.side-bar-menu-item:hover {
  background: #1f2c3d;
}

.selected {
  background: #1f2c3d;
  color: white;
  font-weight: 500;
}

@media print {
  .container {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    flex: 1 1;
    page-break-after: always;
    page-break-before: always;
  }
}

.header {
  display: flex;
  flex-direction: row;
}

.header-text {
  display: flex;
  flex: 1 1;
  flex-grow: 1;
}

.print-area {
  padding: 1em;
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  height: auto;
  border: 2px dashed #bababa;
  flex-direction: column;
}

.print-content {
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  height: inherit;
  flex-direction: column;
  width: 50%;
}

.barcode-text {
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  flex-direction: row;
}

.flex-grow {
  flex: 1 1; 
  flex-grow: 1;
  display: flex;
}

.flex-grow-column {
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  flex-direction: column;
  margin-top: 20px;
}

.flex-grow-column span {
  display: flex;
  margin-top: 4px;
}

.flex-column {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

.flex-column span {
  display: flex;
}

.large-text-bold {
  font-size: 1.4em;
  font-weight: 700;
}

.shipment-name {
  font-size: 1.2em;
  font-weight: 700;
}

.flex-row-grow {
  display: flex;
  flex: 1 1;
  flex-grow: 1;
  flex-direction: row;
}

.print-area-notes {
  border: 1px solid rgba(50, 50, 50, 0.2);
  padding: 8;
  border-radius: 5;
}

.table-container {
  margin-top: 30px;
  border: 1px solid rgba(50, 50, 50, 0.2); 
  border-radius: 5px; 
  flex-direction: column;
}

.table-header {
  height: 30px;
  flex-direction: row;
  display: flex;
  border-bottom: 1px solid rgba(50, 50, 50, 0.2); 
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 8px;
}

.table-header span {
  display: flex;
  flex-grow: 1;
}

.table-content {
  display: flex;
  flex-grow: 1;
  flex: 1 1;
  flex-direction: column;
}
.table-content-row {
  padding-top: 10px;
  padding-bottom: 10px;
  display: flex;
  flex: 1 1;
  flex-wrap: wrap;
  align-items: center; 
  text-justify: center; 
  padding-left: 8px;
  border-bottom: 1px solid rgba(50, 50, 50, 0.2); 
}

.table-content-row span {
  word-break: break-word; 
  flex-wrap: wrap;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
      hyphens: auto;
}

.thank-you {
  font-size: 14px; 
  align-self: center;
  font-weight: 700;
  margin-top: 24;
}

body {
  margin: 0px !important;
  padding: 0;
  font-family: "Roboto";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.loading-container {
  display: flex; 
  flex-direction: column; 
  justify-content: center; 
  align-items: center; 
  height: 100%;
  font-size: 50px;
}
.loading-container div {
  margin-top: 10px;
}

